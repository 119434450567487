import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../layout'
import SEO from '../utils/seo'
import ContainerContactUs from '../assets/styles/pages/contactUs'
import BannerDivider from '../components/bannerDivider'

import { FaMapMarkerAlt, FaPhoneAlt, FaMobileAlt } from 'react-icons/fa'
import { MdMail } from 'react-icons/md'

const ContactUsPage = () => {
    const { regionsAttendance } = useStaticQuery(graphql`
        query {
            regionsAttendance: allStrapiMatrizEFiliais {
                nodes {
                    id
                    name
                    address
                    email
                    landline_phone
                    mobile_phone
                    maps_link
                }
            }
        }
    `)

    const maps = {}

    const [regionSelected, setRegionSelected] = useState(regionsAttendance.nodes[0].id)

    const handleSelectedRegionChange = (region_id) => {
        setRegionSelected(region_id)
    }

    regionsAttendance.nodes.forEach((region) => {
        maps[region.id] = region
    })

    return (
        <Layout>
            <SEO title="Contato" />
            <BannerDivider title="Fale Conosco" subtitle="Excelência no atendimento" />

            <ContainerContactUs className="col-10">
                <section className="regionsContactUs mt-3px">
                    <div className="map-wrapper">
                        <iframe
                            title="map-address-minas-coding"
                            src={maps[regionSelected]?.maps_link}
                            className="map-iframe-contact-us"
                        />

                        {regionsAttendance.nodes.length && (
                            <div className="wrapperChangeLocation">
                                {regionsAttendance.nodes.map((region, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleSelectedRegionChange(region.id)}
                                        className={`btnMapContactUs font-white ${
                                            regionSelected === region.id
                                                ? 'activeBtnContactUs'
                                                : null
                                        }`}
                                    >
                                        {region.name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="infoContact font-white">
                        <h3 className="font-subtitle font-center mb-2">
                            {maps[regionSelected]?.name}
                        </h3>
                        <p>
                            <FaMapMarkerAlt /> {maps[regionSelected]?.address}
                        </p>
                        <p>
                            <MdMail /> {maps[regionSelected]?.email}
                        </p>
                        <p>
                            <FaPhoneAlt /> {maps[regionSelected]?.landline_phone}
                        </p>
                        <p>
                            <FaMobileAlt /> {maps[regionSelected]?.mobile_phone}
                        </p>
                    </div>
                </section>
            </ContainerContactUs>
        </Layout>
    )
}

export default ContactUsPage
