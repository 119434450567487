import styled from 'styled-components'

export default styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .backgroundContentContact {
        padding: 30px 20px;
        width: 60%;
        margin: 20px auto;

        background: #393737;
        color: #f0ebeb;

        text-align: center;
    }

    .regionsContactUs {
        width: 100%;

        display: flex;
        justify-content: space-between;

        text-align: center;
        margin-bottom: 40px;

        .map-wrapper {
            width: 100%;
            flex-basis: 60%;
        }

        .map-iframe-contact-us {
            width: 100%;
            height: 400px;
            box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
        }

        .wrapperChangeLocation {
            display: flex;
            justify-content: center;
            position: relative;
            margin-top: -30px;
            .btnMapContactUs {
                flex-basis: 45%;
                max-width: 175px;
                height: 50px;

                :not(.activeBtnContactUs) {
                    background-color: #393737;
                    border: 1px solid #393737;
                }

                font-weight: 700;
                :hover {
                    cursor: pointer;
                    background-color: #d6373b;
                    border: 1px solid #d6373b;
                }
            }
            .activeBtnContactUs {
                background-color: #d22327;
                border: 1px solid #d22327;
            }
        }

        .infoContact {
            align-self: center;
            flex-basis: 35%;
            background-color: #393737;
            padding: 15px 30px;
            width: 35vw;
            min-width: 300px;
            height: auto;

            h3 {
                text-transform: uppercase;
                font-weight: bold;
            }

            p {
                line-height: 40px;
                text-align: left;
            }
        }

        @media only screen and (max-width: 910px) {
            display: flex;
            flex-direction: column;

            .infoContact {
                order: 1;
                width: 80vw;
                margin: 30px 0 15px;
            }
        }
    }

    .wrapperContact {
        width: 100%;

        .formContact {
            flex-basis: 58%;

            .formInputsContact {
                input,
                select,
                textarea {
                    width: 100%;
                    border: 1px solid #a5a4a4;
                    border-radius: 2px;
                    margin-bottom: 15px;

                    padding: 10px;

                    background-color: #ededed;

                    &:active,
                    &:focus,
                    &:visited {
                        outline: #393737 solid 1px;
                    }
                }

                textarea {
                    resize: none;
                    height: 150px;
                }

                button {
                    float: right;
                    display: flex;
                    align-items: center;
                    transition: all ease-out 2s;
                    .loaderIcon {
                        width: 25px;
                        margin-right: 20px;
                    }
                }
                .btn-loader {
                    :hover {
                        cursor: wait;
                    }
                }
            }
        }

        @media only screen and (max-width: 798px) {
            display: flex;
            flex-direction: column;

            .infoContact {
                order: 1;
                width: 80vw;
                margin-bottom: 30px;
            }

            .formContact {
                order: 2;
                width: 95vw;
                display: flex;
                flex-direction: column;
                text-align: center;

                button {
                    float: none !important;
                }
            }
        }
    }
`
